<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Account Group</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Account Group"
              >
                <v-select
                  v-model="accountgrp"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accgroupOption"
                  placeholder="None"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Bank Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  placeholder="Enter Bank Name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Branch Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Branch Name"
                rules="required"
              >
                <b-form-input
                  v-model="bankbranch"
                  placeholder="Enter Branch Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Account Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Account Name"
                rules="required"
              >
                <b-form-input
                  v-model="accountname"
                  placeholder="Enter Account Name "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Account No"
                rules="required"
              >
                <b-form-input
                  v-model="accountno"
                  type="number"
                  placeholder="Enter Account No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="IFSC Code"
                rules="required"
              >
                <b-form-input
                  v-model="ifsccode"
                  maxlength="11"
                  @input="validateIFSCCodeClick($event)"
                  placeholder="Enter IFSC Code"
                />
                <small v-if="ifsccodeValidate == false" class="text-danger"
                  >Enter Valid IFSC Code</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>QR Code</label>
              <b-form-file
                v-model="qrcode"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'bank','qrcode')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="qrcode" />
          </b-col>

          <b-col md="12">
            <b-form-checkbox v-model="action" inline>
                Show details on sales time
              </b-form-checkbox>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Default</label>
              <v-select
                v-model="Default"
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-button
              class="mt-1"
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button
              class="mt-1 ml-2"
              variant="primary"
              type="submit"
              @click="handleBack"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import attachment from '@/components/Attechment.vue'
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
  BFormCheckbox
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    attachment,
    PinchScrollZoom,
    BFormCheckbox,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
  },
  data() {
    return {
      accountgrp:null,
      name: "",
      bankbranch: "",
      accountname: "",
      accountno: "",
      ifsccode: "",
      ifsccodeValidate: true,
      Default: "",
      qrcode:'',
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      flag: false,
      accgroupOption: [],
      action:false
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Bank Account") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/account/bankaccount");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/account/bankaccount");
          }
        }
      });
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
    this.acountGroup();
  },
  methods: {
    async handleChangeFile(e, type, name) {
      this.flag = true;
      const vue=this
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions).then((response) => {
        vue[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        this.flag = false;
      });
    },
    async acountGroup(id) {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/chartofaccount`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.accgroupOption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEditValue() {
      axios({
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getbankById/${this.$route.params.id}`,
      }).then((response) => {
        this.getEditItems(response.data.data);
      });
    },
    getEditItems(item) {
      item.map((item) => {
        this.name = item.name;
        this.bankbranch = item.bankbranch;
        this.accountname = item.accountname;
        this.accountno = item.accountno;
        this.ifsccode = item.ifsccode;
        this.Default = item.Default;
        this.accountgrp=item.accountgrp
        this.qrcode = item.qrcode
        this.action=item.action?true:false
      });
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    submitForm(e) {
      const obj = {
        name: this.name,
        bankbranch: this.bankbranch,
        accountname: this.accountname,
        accountno: this.accountno,
        ifsccode: this.ifsccode,
        accountgrp:this.accountgrp.id,
        qrcode: this.qrcode,
        action:this.action
        // Default: this.Default,
      };
      const ifEdit = !!this.$route.params.id;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        const accessToken = localStorage.getItem("accessToken");
        const baseApi = process.env.VUE_APP_APIENDPOINT;
        if (success) {
          this.flag = true;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/bank/${this.$route.params.id}`
              : `${baseApi}/bank`,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(obj),
          })
            .then((json) => {
              this.flag = false;

              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/master/account/bankaccount");
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleBack() {
      this.$router.push("/master/account/bankaccount");
    },
  },
};
</script>
